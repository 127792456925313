.module-editor {
  &__container {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
  }

  &__stack {
    &-crumbs {
      display: grid;
      grid-auto-flow: column;
      font-size: 0.75rem;
      justify-content: start;
      margin-bottom: var(--spacing-2);

      button {
        @include button-reset;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;

        &:first-child {
          display: grid;
          grid-auto-flow: column;
          align-items: center;
        }

        svg {
          height: 1rem;
        }

        &:not(:last-of-type):after {
          content: "/";
          margin: 0 var(--spacing-1);
          color: var(--colour-neutral-light-slate-100);
        }

        &:last-of-type {
          color: var(--colour-neutral-light-slate-100);
        }
      }
    }
  }

  &__module {
    &-container {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: var(--spacing-1);
      align-items: center;
      background-color: white;

      @include box;
      @include gradient-background;
      padding: var(--spacing-1);
    }

    &-grab-handle {
      @include button-reset;

      display: grid;
      cursor: ns-resize;

      svg {
        height: 1.25rem;
      }
    }

    &-model {
      background-color: white;
      border: 1px solid var(--colour-neutral-light-slate-25);
      padding: var(--spacing-1);
      border-radius: var(--radius);
      line-height: 1.4;
    }

    &-settings {
      display: grid;
      grid-template-columns: 1fr auto auto auto;
      align-items: center;
      column-gap: var(--spacing-1);

      svg {
        path {
          stroke: var(--colour-neutral-slate);
        }
      }
    }

    &-editor {
      margin-top: var(--spacing-3);
    }
  }

  &__add-module {
    @include control-group;
  }
}

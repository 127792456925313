:root {
  --spacing-1: 0.5rem;
  --spacing-2: 1rem;
  --spacing-3: 1.5rem;
  --spacing-4: 2rem;
  --spacing-5: 2.5rem;
  --spacing-6: 3rem;
  --spacing-7: 4rem;
  --spacing-8: 5rem;
}

@media screen and (max-width: 520px) {
  :root {
    --spacing-1: 0.5rem;
    --spacing-2: 1rem;
    --spacing-3: 1rem;
    --spacing-4: 1rem;
    --spacing-5: 1.5rem;
    --spacing-6: 1.5rem;
    --spacing-7: 2rem;
    --spacing-8: 2.5rem;
  }
}

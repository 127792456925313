.model-list {
  &__body {
    @include box;
    @include gradient-background;

    overflow-y: auto;
  }

  &__models {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
    padding: var(--spacing-1);

    &-no-results {
      margin: var(--spacing-5);
      display: grid;
      grid-auto-flow: row;
      row-gap: var(--spacing-2);

      justify-items: center;

      p {
        text-align: center;
      }

      .icon__search {
        height: 2.5rem;

        path {
          stroke: var(--colour-neutral-slate);
        }
      }
    }
  }

  &__model-detail {
    @include box(true);

    padding: 10px;
    scroll-margin: var(--spacing-1);

    text-decoration: none;
    color: var(--colour-text);

    display: grid;
    grid-template-columns: 1fr auto;

    &:hover {
      color: var(--colour-text);
    }

    p {
      margin-bottom: 0.25rem;
    }

    code {
      color: var(--colour-neutral-light-slate-100);
    }

    &.--deleted {
      p {
        color: var(--colour-neutral-light-slate-100);

        small {
          margin-left: var(--spacing-2);
        }
      }

      code {
        color: var(--colour-neutral-light-slate-75);
      }
    }
  }
}

.webhook-run {
  &__container {
    display: grid;
    row-gap: var(--spacing-2);
    align-content: flex-start;
  }

  &__header {
    @include level;

    &-actions {
      @include control-group;
    }
  }

  &__log {
    &-item {
      @include box;
      padding: var(--spacing-1);
      display: grid;
      row-gap: var(--spacing-1);

      &-header {
        @include level;
      }
    }
  }
}

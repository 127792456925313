.model-list {
  &__footer {
    padding-top: var(--spacing-2);
    margin-top: var(--spacing-2);
    border-top: 1px solid var(--colour-neutral-light-slate-25);

    &-actions {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      margin-bottom: var(--spacing-2);

      & > div {
        display: grid;
        grid-auto-flow: column;
        column-gap: var(--spacing-1);
      }
    }

    .button.is-text {
      font-weight: normal;
    }
  }
}

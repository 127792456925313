.entry-input__create {
  &-container {
    display: grid;
    row-gap: var(--spacing-1);
  }

  &-actions {
    @include control-group;
  }
}

.entry-detail {
  @import "./settings";
  @import "./versions";
  @import "./watchers";
  @import "./editor";

  &__container {
    display: flex;
    flex-direction: row;
    padding: var(--spacing-3);
    max-height: 100%;
    overflow: hidden;
    flex: 1;
    justify-content: center;
  }

  &__meta {
    margin-left: var(--spacing-3);

    display: flex;
    flex-direction: column;

    max-height: 100%;
    overflow: auto;

    width: 16rem;

    @include breakpoint(large) {
      width: 24rem;
    }
  }
}

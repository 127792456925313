.label {
  font-weight: bold;
  display: block;

  & + * {
    margin-top: 0.5rem;
  }
}

.label-container {
  @include level;
  line-height: 1.4;
  margin-bottom: var(--spacing-1);

  button {
    @include button-reset;
    color: var(--colour-primary-leaf-100);
    cursor: pointer;
    font-size: var(--font-size-small);

    &[disabled] {
      color: var(--colour-neutral-light-slate-100);
    }
  }
}

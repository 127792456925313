.markdown-editor {
  &__input {
    textarea {
      background-color: white;
    }
  }

  &__preview {
    margin-top: var(--spacing-2);

    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-2);

    &-header {
      @include level;

      button {
        @include button-reset;
        font-size: var(--font-size-small);
        color: var(--colour-primary-leaf-100);
        text-decoration: underline;
      }
    }

    &-body {
      @include box;
      padding: var(--spacing-1);
      height: 8rem;
      overflow-y: auto;
      line-height: 1.4;
      resize: vertical;

      li + li {
        margin-top: var(--spacing-1);
      }

      p,
      dl,
      ol,
      ul,
      blockquote,
      pre,
      table {
        &:not(:last-child) {
          margin-bottom: var(--spacing-2);
        }
      }

      h1 {
        font-size: 2em;
        margin-bottom: 0.5em;
        &:not(:first-child) {
          margin-top: 1em;
        }
      }
      h2 {
        font-size: 1.75em;
        margin-bottom: 0.5714em;
        &:not(:first-child) {
          margin-top: 1.1428em;
        }
      }
      h3 {
        font-size: 1.5em;
        margin-bottom: 0.6666em;
        &:not(:first-child) {
          margin-top: 1.3333em;
        }
      }
      h4 {
        font-size: 1.25em;
        margin-bottom: 0.8em;
      }
      h5 {
        font-size: 1.125em;
        margin-bottom: 0.8888em;
      }
      h6 {
        font-size: 1em;
        margin-bottom: 1em;
      }

      ol,
      ul,
      li {
        margin: revert;
        padding: revert;
      }

      ol {
        list-style: decimal outside;
        margin-top: 1em;
      }

      ul {
        list-style: disc outside;
        margin-top: 1em;
        ul {
          list-style-type: circle;
          margin-top: 0.5em;
          ul {
            list-style-type: square;
          }
        }
      }

      img {
        max-width: 100%;
      }
    }
  }
}

@mixin level {
  display: grid;
  grid-auto-flow: column;
  column-gap: var(--spacing-2);
  row-gap: var(--spacing-2);

  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 520px) {
    grid-auto-flow: row;
  }
}

@mixin box($hoverable: false) {
  border: 1px solid var(--colour-neutral-light-slate-25);
  background-color: #fff;
  border-radius: var(--radius);

  @if $hoverable {
    &:hover {
      border-color: var(--colour-secondary--sky-blue-120);
    }

    &.active {
      border-color: var(--colour-primary-green-100);
      background-color: var(--colour-primary-green-25);
    }
  }
}

@mixin box-shadow {
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1),
    0px 1px 1px rgba(0, 0, 0, 0.05);
}

@mixin list-shadow {
  box-shadow: 0px 8px 16px 8px rgba(14, 14, 44, 0.1);
}

@mixin alert-shadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.2);
}

@mixin box-large {
  @include box;
  @include box-shadow;

  border-radius: var(--radius-large);
  padding: var(--spacing-5);
}

@mixin box-medium {
  @include box;

  padding: var(--spacing-1);
}

@mixin box-header {
  padding-bottom: var(--spacing-2);
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--colour-neutral-light-slate-25);
}

@mixin container-large {
  max-width: 1440px;
  width: 100%;
}

@mixin container-medium {
  max-width: 675px;
  width: 100%;
}

@mixin help-inline {
  display: flex;
  align-items: center;

  font-size: 0.75rem;
  line-height: 1.4;

  color: var(--colour-neutral-light-slate-100);
  border-left: 1px solid var(--colour-neutral-light-slate-100);

  padding-left: var(--spacing-2);
  margin: 0.25rem 0 0.25rem var(--spacing-2);

  @media screen and (max-width: 520px) {
    margin: 0;
    margin-top: var(--spacing-1);
    border-left-width: 0;
    padding-left: 0;
  }
}

@mixin gradient-background {
  background: linear-gradient(
    180deg,
    rgba(231, 240, 245, 0.1) 0%,
    var(--colour-secondary--sky-blue-25) 100%
  );
}

@mixin button-reset {
  border: none;
  font-size: inherit;
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }

  &:active {
    color: initial;
  }
}

@mixin control-group {
  display: grid;
  grid-auto-flow: column;
  column-gap: var(--spacing-1);
  row-gap: var(--spacing-1);
  justify-content: flex-start;
  align-items: center;
}

@mixin hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

$breakpoints: (
  "small": "(min-width: 480px)",
  "medium": "(min-width: 680px)",
  "large": "(min-width: 1025px)",
  "max": "(max-height: 1200px)",
);

@mixin breakpoint($key) {
  @media #{map-get($breakpoints, "#{ $key }")} {
    @content;
  }
}

.media-search {
  &__container {
    display: grid;
    grid-template-rows: auto 1fr auto;
    row-gap: var(--spacing-2);
  }

  &__body {
    @include box;
    @include gradient-background;

    padding: var(--spacing-1);
    display: grid;
    grid-template-rows: auto 1fr;
    row-gap: var(--spacing-2);
    min-height: 20rem;
    margin-top: var(--spacing-2);
  }

  &__results {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: var(--spacing-1);
    align-items: flex-start;

    position: relative;

    &.--searching {
      grid-template-columns: 1fr;
    }
  }

  &__footer {
    @include control-group;
  }

  &__items {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
  }

  &-item {
    &__container {
      @include button-reset;
      @include box;
      padding: 10px;

      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: var(--spacing-1);
      justify-items: flex-start;

      svg {
        height: 1rem;
      }

      &.--selected {
        background-color: var(--colour-primary-green-25);
        border-color: var(--colour-primary-green-100);
      }

      img {
        height: 40px;
        width: 40px;
      }

      ul {
        list-style-type: none;
        font-size: var(--font-size-small);

        display: grid;
        grid-auto-flow: column;
        column-gap: var(--spacing-1);
        justify-content: start;

        li {
          padding: 0;
          color: var(--colour-neutral-light-slate-100);
        }
      }
    }

    &__detail {
      display: grid;
      grid-auto-flow: row;
      row-gap: var(--spacing-1);
      justify-items: flex-start;

      small {
        color: var(--colour-neutral-light-slate-100);
      }
    }
  }
}

.icon {
  &__danger {
    path {
      fill: var(--colour-danger);
    }
  }

  &__disabled {
    path {
      stroke: var(--colour-neutral-light-slate-100);
    }
  }

  &__search {
    path {
      stroke: var(--colour-neutral-light-slate-100);
    }
  }

  &__success {
    path {
      stroke: var(--colour-success);
    }
  }
}

.button,
.icon-button,
.split-button {
  &.--primary {
    --icon-colour: white;
  }
  &.--secondary {
    --icon-colour: var(--colour-primary-green-140);
  }
  &.--tertiary {
    --icon-colour: var(--colour-neutral-slate);
  }
}

:root {
  --icon-colour: var(--colour-neutral-light-slate-100);
}

.icon {
  &__more {
    path {
      fill: var(--icon-colour);
      stroke: transparent;
    }
  }

  &__check {
    path {
      stroke: var(--icon-colour);
    }
  }

  &__close {
    path {
      stroke: var(--icon-colour);
    }
  }

  &__loading {
    path {
      stroke: var(--icon-colour);
    }
  }

  &__search {
    path {
      stroke: var(--icon-colour);
    }
  }

  &__success {
    path {
      stroke: var(--icon-colour);
    }
  }

  &__saved {
    path {
      fill: var(--icon-colour);
    }
  }

  &__next,
  &__down {
    path {
      stroke: var(--icon-colour);
    }
  }

  &__settings {
    path {
      stroke: var(--icon-colour);
    }
  }

  &__share {
    path {
      stroke: var(--icon-colour);
    }
  }

  &__edit {
    path {
      fill: var(--icon-colour);
    }
  }

  &__plus {
    path {
      stroke: var(--icon-colour);
    }
  }

  &__delete-alt {
    &--hat {
      stroke: var(--icon-colour);
    }
    &--body {
      fill: var(--icon-colour);
    }
  }

  &__media-other {
    path {
      fill: var(--icon-colour);
      stroke: transparent;
    }
  }

  &__folder,
  &__folder-open {
    rect,
    path {
      fill: var(--icon-colour);
      stroke: transparent;
    }
  }

  &__folder-uncategorised {
    path:last-child {
      fill: var(--icon-colour);
      stroke: transparent;
    }
    path:first-child {
      stroke: var(--icon-colour);
    }
  }

  &__move {
    path {
      stroke: var(--icon-colour);
    }
  }
}

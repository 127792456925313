:root {
  --simple-nav-logo-height: var(--spacing-5);
  --simple-nav-margin: var(--spacing-5);
}

.navbar-simple {
  margin-bottom: var(--spacing-2);
  padding-bottom: var(--spacing-1);
  border-bottom: 1px solid var(--colour-neutral-light-slate-25);

  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-content: space-between;

  svg {
    height: var(--simple-nav-logo-height);
  }

  @media screen and (min-width: 520px) {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom-width: 0;
  }
}

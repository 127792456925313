@import "./card/index";
@import "./folders/index";
@import "./items/index";
@import "./uploads/index";

.media {
  .columns {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--spacing-2);
  }
}

.media-list {
  &__item {
    display: flex;
    color: var(--colour-text);

    &--icon {
      flex: 0;
      margin-right: var(--spacing-1);
      color: var(--colour-text);
    }

    &--detail {
      flex: 1;
    }
  }

  &__upload {
    flex: 1;
    flex-direction: column;
    align-items: normal;
  }
}

.upload-list {
  &__item {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    &--name {
      flex: 1;

      p {
        word-break: break-all;
      }
    }
    // &--size {
    // }
    // &--actions {
    // }
  }
}

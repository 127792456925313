.media-folder-list {
  &__container {
    @include box;

    padding: var(--spacing-3);

    display: grid;
    grid-template-rows: auto 1fr auto;
    row-gap: var(--spacing-2);
  }

  &__header {
    @include level;

    .button {
      display: grid;
      grid-auto-flow: column;
      column-gap: var(--spacing-1);
      svg {
        height: 1.5rem;
        path,
        circle,
        line {
          stroke: var(--colour-neutral-slate);
        }

        path:first-child {
          fill: var(--colour-primary-green-100);
        }
      }
    }
  }

  &__body {
    @include box;
    @include gradient-background;

    padding: var(--spacing-1);
  }

  &__items {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
    align-content: flex-start;
  }

  &__item {
    @include box(true);
    padding: 10px;

    display: grid;

    grid-template-columns: auto 1fr auto;
    column-gap: var(--spacing-1);
    align-items: center;

    button {
      @include button-reset;

      outline: none; // TODO: need this for accessibility

      svg {
        height: 20px;
        width: 20px;

        stroke: var(--colour-neutral-light-slate-100);
      }
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    &.active,
    &.--dragover {
      border-color: var(--colour-primary-green-100);
      background-color: var(--colour-primary-green-25);
    }

    &.--expandable {
      grid-template-columns: auto auto 1fr auto;
    }

    &.--editing {
      grid-template-columns: 1fr auto;
      background-color: var(--colour-neutral-dark-cloud);
      border-color: var(--colour-neutral-light-slate-25);

      input {
        font-size: inherit;
        background-color: transparent;
        border: none;
        width: 100%;
        outline: none;
      }
    }

    &.--deleting {
      .media-folder-list__item-details {
        text-decoration: line-through;
      }
    }

    &-details {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: var(--spacing-1);
      justify-items: start;
      align-items: center;
    }

    &-icon {
      height: 20px;
      width: 20px;
      cursor: grab;
    }

    &-actions {
      @include control-group;
    }
  }
}

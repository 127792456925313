.input-with-select {
  flex: 1;
  display: flex;
  border: 2px solid var(--colour-neutral-light-slate-25);
  border-radius: var(--radius);
  align-items: center;

  input {
    flex: 1;
    border: none;
    background-color: transparent;
    padding: 0.625rem 0.75rem;

    font-size: inherit;

    &:read-only {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  select {
    border: none;
    background-color: transparent;
    padding: 0.625rem 0.75rem;
    font-size: inherit;
    margin-right: 0.625rem;
  }
}

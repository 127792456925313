.split-button {
  &__triggers {
    display: inline-grid;
    grid-template-columns: 1fr auto;
  }

  &__icon {
    &--left,
    &--right,
    &--loading {
      height: 20px;
      width: 20px;
    }
  }

  &__action {
    @include button-reset;

    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
    border-width: 2px;
    border-style: solid;
    border-right-width: 0;
    padding: 10px 18px;
    text-decoration: none;

    font-weight: bold;
    outline: none;
    display: grid;
    column-gap: 8px; // TODO: convert px values to rem

    &.--has-icon-left {
      grid-template-columns: auto 1fr;
    }

    &.--has-icon-right {
      grid-template-columns: 1fr auto;
    }

    &.--has-icon-both {
      grid-template-columns: auto 1fr auto;
    }

    &.--loading {
      .split-button__icon--loading {
        animation: spin 2s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
  }

  &__content {
    padding: 2px 0px;
    font-weight: bold;
  }

  &__toggle {
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
    padding: 8px;

    border-width: 2px;
    border-style: solid;

    svg {
      height: 20px;
      width: 20px;

      pointer-events: none;
    }
  }

  &.--primary {
    .split-button__action {
      background-color: var(--colour-primary-green-100);
      border-color: var(--colour-primary-green-100);
      color: white;
    }
    .split-button__toggle {
      background-color: var(--colour-primary-green-120);
      border-color: var(--colour-primary-green-120);
      svg {
        path {
          stroke: white;
        }
      }
    }

    &:focus-within,
    &.--focus {
      .split-button__action,
      .split-button__toggle {
        border-color: var(--colour-primary-green-140);
      }
    }

    &:hover,
    &.--hover {
      .split-button__action {
        background-color: var(--colour-primary-green-120);
        border-color: var(--colour-primary-green-120);
      }
      .split-button__toggle {
        background-color: var(--colour-primary-green-140);
        border-color: var(--colour-primary-green-140);
      }
    }
  }

  &.--secondary {
    .split-button__action {
      background-color: var(--colour-primary-green-25);
      border-color: var(--colour-primary-green-25);
      color: var(--colour-primary-green-140);
    }
    .split-button__toggle {
      background-color: var(--colour-primary-green-50);
      border-color: var(--colour-primary-green-50);

      svg {
        path {
          stroke: var(--colour-primary-green-140);
        }
      }
    }

    &:hover,
    &.--hover {
      .split-button__action {
        background-color: var(--colour-primary-green-50);
        border-color: var(--colour-primary-green-50);
      }
      .split-button__toggle {
        background-color: var(--colour-primary-green-75);
        border-color: var(--colour-primary-green-75);
      }
    }

    &:focus-within,
    &.--focus {
      .split-button__action,
      .split-button__toggle {
        border-color: var(--colour-primary-green-140);
      }
    }
  }

  &.--tertiary {
    .split-button__action {
      background-color: white;
      border-color: var(--colour-primary-green-100);
      color: var(--colour-neutral-slate);

      border-width: 1px;
      border-right-width: 0;
      padding: 11px 19px;
    }
    .split-button__toggle {
      background-color: var(--colour-primary-green-25);
      border-color: var(--colour-primary-green-100);

      border-width: 1px;
      padding: 9px;

      svg {
        path {
          stroke: var(--colour-neutral-slate);
        }
      }
    }

    &:hover,
    &.--hover {
      .split-button__action {
        background-color: var(--colour-primary-green-50);
        border-color: var(--colour-primary-green-50);
      }
      .split-button__toggle {
        background-color: var(--colour-primary-green-75);
        border-color: var(--colour-primary-green-75);
      }
    }

    &:focus-within,
    &.--focus {
      .split-button__action {
        border-color: var(--colour-primary-green-140);

        border-width: 2px;
        border-right-width: 0;
        padding: 10px 18px;
      }
      .split-button__toggle {
        border-color: var(--colour-primary-green-140);

        border-width: 2px;
        padding: 8px;
      }
    }
  }

  &.--disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.--loading {
    pointer-events: none;
  }
}

.model-list {
  &__header {
    padding-bottom: var(--spacing-2);
    margin-bottom: var(--spacing-2);
    border-bottom: 1px solid var(--colour-neutral-light-slate-25);

    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
  }
}

.navbar-project {
  background-color: var(--colour-primary-green-100);

  .navbar {
    &__container {
      display: grid;

      grid-template-columns: auto auto 1fr auto;
      align-items: stretch;
    }

    &__brand {
      height: 3rem;
      width: 3rem;

      padding: 0.5rem;
      margin: 6px;

      display: grid;
      place-items: center;

      svg {
        .pokko__frog--outline {
          fill: white;
        }
      }
    }

    &__left {
      grid-column: 2;
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      column-gap: var(--spacing-2);
      margin-left: var(--spacing-1);
    }

    &__right {
      grid-column: 4;
      display: flex;
      align-items: center;

      padding-right: 6px;

      column-gap: var(--spacing-2);
    }

    &__project-selector {
      &-button {
        font-size: inherit;
        padding: 10px;
        border-radius: var(--radius);
        border: none;
        max-width: 20rem;

        background-color: var(--colour-primary-green-120);

        display: grid;
        justify-content: space-between;
        align-items: center;
        grid-auto-flow: column;
        column-gap: var(--spacing-1);
        cursor: pointer;
        position: relative;

        svg {
          height: 1.25rem;

          path {
            stroke: white;
          }
        }

        strong,
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.4;
          white-space: nowrap;

          color: white;
        }

        &:hover,
        &.active {
          background-color: var(--colour-primary-green-120);
        }
      }

      &-dropdown {
        @include box;
        @include box-shadow;

        border-color: var(--colour-neutral-light-slate-50);

        position: absolute;
        top: 3.75rem;
        width: 25rem;
        padding: var(--spacing-3);
        z-index: 100;
      }

      &-projects {
        max-height: calc(100vh - 10rem);
        display: grid;
        grid-auto-flow: row;
        row-gap: var(--spacing-2);
        overflow: auto;

        & > li {
          &:not(:last-child) {
            padding-bottom: var(--spacing-2);
            border-bottom: 1px solid var(--colour-neutral-light-slate-25);
          }

          & > div {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            margin-bottom: var(--spacing-2);

            a {
              font-size: var(--font-size-small);
            }
          }

          strong {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.4;
          }

          svg {
            height: 1rem;
          }

          ul {
            display: flex;
            flex-wrap: wrap;

            li {
              font-size: var(--font-size-small);
              margin-right: var(--spacing-1);
              margin-bottom: var(--spacing-1);

              a {
                text-decoration: none;
                color: var(--colour-neutral-slate);
                padding: 4px 0;
                display: block;
              }

              &.is-active {
                background-color: var(--colour-secondary--sky-blue-100);
                border-radius: var(--radius);

                a {
                  padding: 4px 8px;
                }
              }
            }
          }
        }
      }
    }

    &__dropdown {
      position: relative;
      align-self: stretch;
      align-content: center;
      display: grid;

      &-button {
        background-color: transparent;
        padding: 0.75rem;
        line-height: 1.4;
        cursor: pointer;
        text-decoration: none;
        color: white;
        display: grid;
        grid-auto-flow: column;
        column-gap: var(--spacing-1);

        svg {
          height: 1.25rem;
          margin-bottom: -0.25rem;

          path {
            stroke: white;
          }
        }

        &.active,
        &:hover {
          color: var(--colour-neutral-dark-cloud);
        }
      }

      &-content {
        @include box;
        @include box-shadow;

        border-color: var(--colour-neutral-light-slate-50);

        position: absolute;
        top: 3.75rem;

        z-index: 100;
        display: none;
      }

      &.--right {
        .navbar__dropdown-content {
          right: 0;
        }
      }

      &-links {
        display: grid;
        min-width: 10rem;
        padding: var(--spacing-1);

        li {
          a {
            color: var(--colour-neutral-slate);
            text-decoration: none;
            display: block;
            line-height: 1.4;
            padding: var(--spacing-1);
            white-space: nowrap;

            &.active,
            &:hover {
              color: var(--colour-neutral-light-slate-100);
            }
          }
        }
      }

      &-separator {
        height: 1px;
        background-color: var(--colour-neutral-light-slate-25);
        margin: var(--spacing-1) 0;
      }

      &:hover {
        .navbar__dropdown-content {
          display: block;
        }
      }
    }

    &__item {
      text-decoration: none;
      color: white;
      padding: 0.75rem;
      line-height: 1.4;

      &:hover {
        color: var(--colour-neutral-dark-cloud);
        cursor: pointer;
      }

      &.active {
        color: var(--colour-neutral-dark-cloud);
      }
    }

    &__search {
      margin: 0 1rem;
    }

    &__profile {
      background-color: var(--colour-primary-green-120);
      border-radius: 1.5rem;
      padding: 0.75rem;

      svg {
        height: 1.25rem;

        path {
          fill: white;
        }
      }
    }
  }

  .webhooks-dropdown {
    &__container {
      display: grid;
      grid-auto-flow: row;
      row-gap: var(--spacing-3);
      padding: var(--spacing-2);
      width: 20rem;

      strong {
        display: block;
      }
    }

    &__list {
      display: grid;
      row-gap: var(--spacing-1);

      li {
        @include level;

        a {
          text-decoration: none;
          color: inherit;

          &:hover,
          &.active {
            color: var(--colour-neutral-light-slate-100);
          }
        }
      }
    }
  }
}

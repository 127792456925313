.dropdown-button {
  &__trigger {
    @include button-reset;

    display: grid;
    grid-template-columns: 1fr auto;
    padding: 0.75rem 1.5rem;
    padding-right: 0.75rem;
    border-radius: var(--radius);
    background-color: var(--colour-primary-green-25);
    color: var(--colour-neutral-slate);
    font-weight: bold;
    align-items: center;
    column-gap: var(--spacing-1);

    &--icon {
      line-height: 0;
    }

    svg {
      height: 20px;
      width: 20px;
      pointer-events: none;
    }
  }
}

.pagination {
  &__container {
    display: grid;
    column-gap: var(--spacing-2);
    grid-auto-flow: column;

    justify-content: end;
    align-items: center;
  }

  &__display {
    font-size: var(--font-size-small);

    color: var(--colour-neutral-light-slate-100);
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    column-gap: var(--spacing-2);
    button {
      @include button-reset;

      svg {
        height: 20px;

        path {
          stroke: var(--colour-neutral-slate);
        }
      }

      &[disabled] {
        svg {
          path {
            stroke: var(--colour-neutral-light-slate-100);
          }
        }
      }
    }
  }
}

.value-editor {
  @include box-medium;

  padding: var(--spacing-3);
  height: 100%;
  overflow: auto;

  .header {
    color: var(--colour-neutral-light-slate-75);
  }

  &__field {
    &:not(:last-child) {
      margin-bottom: var(--spacing-5);
    }

    &-header {
      @include level;
      margin-bottom: var(--spacing-1);

      & > .message {
        margin-bottom: var(--spacing-1);
      }

      & > span {
        font-weight: 500;
        color: var(--colour-neutral-light-slate-100);
      }

      &-actions {
        display: grid;
        grid-auto-flow: column;
        column-gap: var(--spacing-1);
      }

      button {
        font-size: 0.75em;
        text-decoration: none;
        font-weight: normal;
      }
    }

    &-content {
      display: grid;
      grid-auto-flow: row;
      row-gap: var(--spacing-1);
    }
  }
}

.env-import {
  &__container {
    @include box-large;

    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 0;
  }

  &__header {
    @include box-header;
    @include level;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    min-height: 0;
    column-gap: var(--spacing-2);
  }

  &__config {
    display: grid;
    grid-template-rows: auto 1fr;
    row-gap: var(--spacing-2);
    min-height: 0;

    &--picker {
      overflow: auto;

      ul {
        display: grid;
        grid-auto-flow: row;
        row-gap: var(--spacing-1);

        li {
          @include control-group;
        }
      }
    }
  }

  &__package {
    display: grid;
    grid-template-rows: auto auto;
    row-gap: var(--spacing-2);
    align-content: flex-start;

    .textarea {
      font-family: var(--font-family-mono);
    }

    &--actions {
      padding-top: var(--spacing-2);
      border-top: 1px solid var(--colour-neutral-light-slate-25);
    }
  }
}

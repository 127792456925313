@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.button {
  @include button-reset;
  text-decoration: none;

  position: relative;
  display: inline-grid;
  padding: 10px 18px;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  outline: none;
  column-gap: 8px; // TODO: convert px values to rem
  justify-content: center;

  &.--has-icon-left {
    grid-template-columns: auto auto;
  }

  &.--has-icon-right {
    grid-template-columns: auto auto;
  }

  &.--has-icon-both {
    grid-template-columns: auto auto auto;
  }

  &.--small {
    padding: 7.5px 8px;
    font-size: var(--font-size-small);

    .button__content {
      font-weight: normal;
    }

    .button__icon {
      &--left,
      &--right,
      &--loading {
        height: 16px;
        width: 16px;
      }
    }

    &.--tertiary {
      padding: 7.5px 8px;

      &:focus,
      &.--focus {
        padding: 6.5px 7px;
        // border-width: 1px;
      }
    }
  }

  &__content {
    padding: 2px 0px;
    font-weight: bold;
  }

  &__icon {
    &--left,
    &--right,
    &--loading {
      height: 20px;
      width: 20px;
    }
  }

  &.--primary {
    background-color: var(--colour-primary-green-100);
    border-color: var(--colour-primary-green-100);
    color: white;

    &:hover,
    &.--hover {
      background-color: var(--colour-primary-green-120);
      border-color: var(--colour-primary-green-120);
    }

    &:focus,
    &.--focus {
      border-color: var(--colour-primary-green-140);
    }
  }

  &.--secondary {
    background-color: var(--colour-primary-green-25);
    border-color: var(--colour-primary-green-25);
    color: var(--colour-primary-green-140);

    &:hover,
    &.--hover {
      background-color: var(--colour-primary-green-50);
      border-color: var(--colour-primary-green-50);
    }

    &:focus,
    &.--focus {
      border-color: var(--colour-primary-green-140);
    }
  }

  &.--tertiary {
    background-color: white;
    border-color: var(--colour-primary-green-100);
    color: var(--colour-neutral-slate);
    border-width: 1px;
    padding: 11px 19px;

    &:hover,
    &.--hover {
      background-color: var(--colour-primary-green-25);
      border-color: var(--colour-primary-green-100);
    }

    &:focus,
    &.--focus {
      border-width: 2px;
      padding: 10px 20px;

      border-color: var(--colour-primary-green-140);
    }
  }

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }

  &.--loading {
    pointer-events: none;

    .button__icon--loading {
      animation: spin 2s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &.--text-only {
      .button__content {
        opacity: 0;
      }

      .button__icon--loading {
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
      }

      &.--small {
        .button__icon--loading {
          position: absolute;
          top: calc(50% - 9px);
          left: calc(50% - 9px);
        }
      }
    }
  }
}

.media-input {
  &__container {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-2);
  }

  &__display {
    display: grid;
    grid-template-columns: 1fr auto auto;
    column-gap: var(--spacing-1);
  }

  &__input {
    label {
      display: block;
    }

    input[type="file"] {
      @include hidden;
    }
  }

  &__value {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: var(--spacing-1);
  }
}

@import "./components/index";

.model-field {
  &__container {
    @include box;

    padding: var(--spacing-2);
    display: grid;
    grid-template-columns: 1fr auto;
    place-items: start;
  }

  &__detail {
    display: grid;

    h3 {
      font-size: 1rem;
      margin-bottom: var(--spacing-1);
    }

    code {
      display: block;
      color: var(--colour-neutral-light-slate-100);
      margin-bottom: var(--spacing-2);
    }
  }

  &__actions {
    .button {
      svg {
        height: 1.25rem;
        line-height: 1;
      }
    }
  }
}

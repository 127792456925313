&-item {
  &__container {
    @include box;
    padding: 10px;
    background-color: white;
    position: relative;
    overflow: hidden;

    &:not(.--complete) {
      span {
        opacity: 0.4;
      }
    }
  }

  &__progress {
    background-color: white;
    position: absolute;
    display: block;
    z-index: 0;
    appearance: none;
    padding: 0;
    margin: 0;

    &::-webkit-progress-bar {
      background-color: white;
    }
    &::-webkit-progress-value {
      background-color: var(--colour-primary-leaf-25);
    }
    &::-moz-progress-bar {
      background-color: var(--colour-primary-leaf-25);
    }
    &::-ms-fill {
      background-color: var(--colour-primary-leaf-25);
    }

    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &__body {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: var(--spacing-1);

    span {
      line-height: 1.4;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    svg {
      height: 1.4rem;
    }
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    column-gap: calc(var(--spacing-1) / 2);
  }

  &__icon {
    @include button-reset;
    line-height: 0;
  }
}

.select {
  background-color: #fff;
  border: 1px solid var(--colour-neutral-light-slate-25);
  border-radius: var(--radius);
  display: grid;

  &:hover {
    border-color: var(--input-border-colour-hover);
  }

  &.is-danger {
    background-color: var(--input-background-colour-danger);
    border-color: var(--input-border-colour-danger);
    box-shadow: 0px 0px 0px 2px rgba(241, 43, 44, 0.2);
  }

  select {
    border: none;
    padding: 10px 14px;
    padding-right: var(--spacing-3);
    margin: 0;
    font-size: inherit;
    line-height: 1.4;

    appearance: none;

    text-overflow: ellipsis;

    background: transparent
      url('data:image/svg+xml;utf8,<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L6 6L11 1" stroke="black" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>')
      no-repeat;
    background-position: right var(--spacing-1) top 50%;

    option {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .control.has-icon-right & {
      background-position: right var(--spacing-4) top 50%;
    }
  }
}

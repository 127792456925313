.model-detail {
  &__container {
    display: grid;
    row-gap: var(--spacing-4);
    grid-template-rows: auto 1fr auto;
    overflow: auto;
  }

  &__header {
    @include level;

    &-actions {
      @include control-group;
    }
  }

  &__footer {
    @include control-group;
  }

  &__body {
    display: grid;
    overflow: auto;
    align-content: flex-start;
    row-gap: var(--spacing-2);

    &-no-results {
      max-width: 25rem;

      margin: var(--spacing-5);
      display: grid;
      grid-auto-flow: row;
      row-gap: var(--spacing-2);

      justify-items: center;
      justify-self: center;

      p {
        text-align: center;
      }

      .icon__search {
        height: 2.5rem;

        path {
          stroke: var(--colour-neutral-slate);
        }
      }
    }
  }

  &__fields {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
  }

  &__usage {
    @include box;
    @include box-medium;
  }
}

@import "./components/index";

button.entry-card__container {
  @include button-reset;

  text-align: left;
  padding: var(--spacing-2);
}

.entry-card {
  &__container {
    &.--selectable {
      @include box(true);
    }

    &:not(.--selectable) {
      @include box;
    }

    padding: var(--spacing-2);

    display: grid;
    align-items: start;

    color: inherit;
    text-decoration: none;
    row-gap: var(--spacing-1);

    &:hover {
      color: inherit;
    }
  }

  &__name,
  &__meta,
  &__detail {
    display: grid;
    grid-auto-flow: column;
    column-gap: var(--spacing-2);
    align-content: center;

    strong a {
      text-decoration: none;
      color: inherit;
    }
  }

  &__name {
    grid-template-columns: 1fr auto;
  }

  &__meta {
    justify-content: start;

    font-size: var(--font-size-small);
    color: var(--colour-neutral-light-slate-100);
  }

  &__detail {
    justify-content: space-between;
  }

  &__actions {
    button {
      @include button-reset;
      font-size: var(--font-size-small);
      color: var(--colour-primary-leaf-100);
    }
  }
}

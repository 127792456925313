.display-date {
  @include control-group;
  display: inline-grid;
  column-gap: var(--spacing-1);

  strong {
    font-weight: normal;
    color: var(--colour-text);
    display: block;
  }
}

.model-list {
  display: grid;
  grid-template-rows: auto 1fr auto;

  @include box;
  padding: var(--spacing-3);
  min-height: 0;
}

@import "./footer";
@import "./header";
@import "./body";

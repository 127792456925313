.tag {
  font-size: var(--font-size-small);

  padding: 4px 8px;
  line-height: 1.4;
  background: var(--colour-secondary--sky-blue-25);
  border-radius: var(--radius);
  display: inline-block;
}

a.tag {
  text-decoration: none;
  color: inherit;
}

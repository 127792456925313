.media-card {
  &__item {
    @include button-reset;
    @include box;
    padding: 10px;
    display: flex;
    gap: var(--spacing-1);

    touch-action: none;

    &-name {
      display: grid;
      grid-template-columns: auto 1fr auto;
      column-gap: var(--spacing-1);

      svg {
        height: 1rem;
      }

      img {
        height: 2.25rem;
        width: 2.25rem;
      }
    }

    &-meta {
      min-width: 0;
      text-align: left;

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.2;
      }

      ul {
        list-style-type: none;
        font-size: var(--font-size-small);
        margin-top: var(--spacing-1);

        display: grid;
        grid-auto-flow: column;
        column-gap: var(--spacing-1);
        justify-content: start;

        li {
          padding: 0;
          color: var(--colour-neutral-light-slate-100);
        }
      }
    }

    &-expanded {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-1);
      margin-top: var(--spacing-2);
      code {
        font-size: var(--font-size-small);
      }

      img {
        height: 500px;
        width: 500px;
      }
    }

    &.--selected {
      border-color: var(--colour-primary-green-100);
      background-color: var(--colour-primary-green-25);

      cursor: grab;
    }

    &.--clickable {
      cursor: pointer;
    }

    &:not(.--uploaded) {
      opacity: 0.4;
    }
  }
}

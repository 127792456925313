.dropdown {
  &__container {
    position: relative;
  }

  &__toggle {
    outline: none;
  }

  &__content {
    @include box;
    @include box-shadow;

    position: absolute;
    top: calc(100% + var(--spacing-1) / 2);
    right: 0;
    z-index: 2;
  }

  &__menu {
    display: grid;
    padding: var(--spacing-2);
    row-gap: var(--spacing-3);
    min-width: 12rem;

    &--item {
      button {
        @include button-reset;
        text-align: left;
      }

      a {
        color: inherit;
        text-decoration: none;
      }
    }

    &--spacer {
      height: 1px;
      background-color: var(--colour-neutral-light-slate-25);
    }
  }
}

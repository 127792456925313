:root {
  --pokko-logo-colour: var(--colour-neutral-onyx);
  --pokko-logo-accent: var(--colour-primary-green-50);
}

// .is-dark {
//   --pokko-logo-colour: #fff;
// }

.pokko {
  &__text,
  &__frog--outline {
    fill: var(--pokko-logo-colour);
    transition: all 200ms linear;
  }

  &__frog--accent {
    fill: var(--pokko-logo-accent);
    transition: all 200ms linear;
  }
}

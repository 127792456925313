.webhook-list {
  &__container {
    @include box;

    padding: var(--spacing-2);

    display: grid;

    grid-template-rows: 1fr auto;
    overflow: hidden;
    row-gap: var(--spacing-2);
  }

  &__body {
    @include box;
    @include gradient-background;
    padding: var(--spacing-1);

    min-height: 0;
    overflow: auto;

    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
    align-content: flex-start;
  }

  &__item {
    @include box(true);
    padding: var(--spacing-1);
    text-decoration: none;
    color: inherit;

    &:hover {
      color: inherit;
    }

    display: grid;
    row-gap: var(--spacing-1);

    time {
      color: var(--colour-neutral-light-slate-100);
      font-size: var(--font-size-small);
    }
  }

  &__actions {
    @include control-group;
    padding-top: var(--spacing-2);
    border-top: 1px solid var(--colour-neutral-light-slate-25);
  }
}

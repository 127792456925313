.table {
  width: 100%;

  thead {
    tr {
      th {
        text-align: left;
      }
    }
  }

  td,
  th {
    padding: 0.25rem;
  }

  tbody {
    tr {
      &:hover {
        background-color: var(--colour-neutral-light-slate-25);
      }
    }
  }
}

:root {
  --font-size-base: 16px;
  --font-size-small: 0.75rem;
  --font-family: "Inter";
  --font-family-mono: "IBM Plex Mono";

  --radius-large: 0.75rem;
  --radius: 0.5rem;
  --radius-small: 0.25rem;
}

&__settings {
  @include box;
  padding: var(--spacing-3);

  &-name,
  &-model,
  &-dates,
  &-links {
    &:not(:last-child) {
      margin-bottom: var(--spacing-2);
      padding-bottom: var(--spacing-2);
      border-bottom: 1px solid var(--colour-neutral-light-slate-25);
    }
  }

  &-model {
    strong {
      display: block;
      margin-bottom: var(--spacing-2);
    }
  }

  &-dates {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-2);

    font-size: var(--font-size-small);
    color: var(--colour-neutral-light-slate-100);

    strong {
      font-weight: normal;
      color: var(--colour-neutral-slate);
      line-height: 1.2;
    }

    & > time {
      display: grid;
      // grid-auto-flow: row;
      row-gap: var(--spacing-1);
      justify-content: start;

      grid-template:
        "label label label"
        "span1 span2 span3";

      strong {
        grid-area: label;
      }

      span {
        line-height: 1.2;
        min-width: 0;
        overflow: auto;
        white-space: nowrap;
      }

      @include breakpoint(large) {
        grid-template: unset;
        grid-auto-flow: column;
        column-gap: var(--spacing-1);

        strong {
          grid-area: unset;
        }
      }
    }
  }

  &-links {
    font-size: var(--font-size-small);

    strong {
      display: block;
      color: var(--colour-neutral-slate);
      line-height: 1.2;
      margin-bottom: var(--spacing-1);
    }

    ul {
      display: grid;
      grid-auto-flow: column;
      column-gap: var(--spacing-2);
      row-gap: var(--spacing-1);
      justify-content: start;
    }
  }

  &-actions {
    @include control-group;
  }
}

.richtext {
  &.input {
    background-color: white;
    padding: 0;
  }

  &__content {
    padding: 5px 10px;
    height: 40vh;
    overflow: auto;
    resize: vertical;
  }

  &__toolbar {
    padding: 5px 10px;
    margin-bottom: 0.5rem;

    border-bottom: 1px solid var(--colour-neutral-light-slate-25);

    display: grid;
    grid-auto-flow: column;
    column-gap: var(--spacing-1);
    row-gap: var(--spacing-1);
    justify-content: start;

    button {
      border: none;
      background-color: transparent;
      padding: 0.5rem;
      font-size: inherit;
      color: inherit;

      svg {
        height: 1rem;
        width: 1rem;
      }

      display: grid;
      align-items: center;
      border-radius: var(--radius-small);

      &:hover {
        background-color: var(--colour-secondary--sky-blue-25);
      }

      &.active {
        background-color: var(--colour-secondary--sky-blue-100);
      }
    }

    &--embed {
      display: grid;
      column-gap: 0.25rem;
      grid-template-columns: 1fr auto;
    }
  }

  &__module {
    @include box;
    @include box-medium;

    margin: 1rem 0;

    .value-editor {
      padding: 0;
      margin: 0;
      border-width: 0;
    }
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h1,
  h2,
  p,
  li,
  code,
  blockquote {
    line-height: 1.4;
  }

  h1,
  h2,
  p,
  ol,
  ul,
  blockquote {
    margin-bottom: 0.5rem;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid var(--colour-neutral-light-slate-25);
  }
}

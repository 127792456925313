:root {
  --simple-layout-main-margin: 5rem;
  --simple-layout-padding: var(--spacing-4);
}

.simple-layout {
  padding: var(--simple-layout-padding);

  main {
    margin-top: 0;

    display: grid;
    place-items: center;
  }

  @media screen and (min-width: 520px) {
    main {
      margin-top: var(--simple-layout-main-margin);
    }
  }
}

.project-settings {
  &__container {
    @include box;
    @include container-medium;

    display: grid;
    row-gap: var(--spacing-3);
    padding: var(--spacing-2);
    align-content: flex-start;
  }

  &__header {
    @include level;
    @include box-header;
    margin-bottom: 0;
  }

  &__name {
    display: grid;
    row-gap: var(--spacing-2);
  }

  &__field-inline {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: var(--spacing-1);
  }

  &__tokens {
    display: grid;
    row-gap: var(--spacing-3);

    &-header {
      @include level;

      &-actions {
        @include control-group;
        font-size: (var(--font-size-small));
      }

      button {
        @include button-reset;
        color: var(--colour-primary-leaf-100);
      }
    }

    &-usage {
      min-width: 0;
      display: grid;
      row-gap: var(--spacing-2);

      pre {
        @include box;
        padding: 10px;
        overflow-x: auto;
        line-height: 1.4;
      }
    }

    &-actions {
      @include control-group;
    }

    &-list {
      display: grid;
      row-gap: var(--spacing-1);

      li {
        @include box;
        display: grid;
        grid-template-columns: 1fr auto auto;
        column-gap: var(--spacing-2);
        align-items: center;
        padding: 10px;

        code {
          line-height: 1.2;
        }

        button {
          @include button-reset;
          color: var(--colour-primary-leaf-100);
          font-size: var(--font-size-small);
        }
      }
    }
  }

  &__environments {
    display: grid;
    row-gap: var(--spacing-3);

    &-header {
      @include level;
    }
  }

  &__environment {
    &-list {
      display: grid;
      row-gap: var(--spacing-1);

      li {
        @include box;
        display: grid;
        padding: 10px;
        row-gap: var(--spacing-2);
      }
    }

    &-name {
      @include button-reset;
      @include level;

      strong {
        line-height: 1.2;
      }
    }

    &-detail {
      display: grid;
      row-gap: var(--spacing-2);
    }

    &-actions {
      @include control-group;
    }
  }

  &__targets {
    display: grid;
    row-gap: var(--spacing-3);

    &-header {
      @include level;
    }
  }

  &__target {
    &-list {
      display: grid;
      row-gap: var(--spacing-1);

      li {
        @include box;
        display: grid;
        padding: 10px;
        row-gap: var(--spacing-2);
      }
    }

    &-name {
      @include button-reset;
      @include level;

      strong {
        line-height: 1.2;
      }
    }

    &-detail {
      display: grid;
      row-gap: var(--spacing-2);
    }

    &-actions {
      @include control-group;
    }
  }

  &__websites {
    display: grid;
    row-gap: var(--spacing-3);

    &-header {
      @include level;
    }
  }

  &__website {
    &-list {
      display: grid;
      row-gap: var(--spacing-1);

      li {
        @include box;
        display: grid;
        padding: 10px;
        row-gap: var(--spacing-2);
      }
    }

    &-name {
      @include button-reset;
      @include level;

      strong {
        line-height: 1.2;
      }
    }

    &-detail {
      display: grid;
      row-gap: var(--spacing-2);
    }

    &-actions {
      @include control-group;
    }
  }
}

&__results {
  display: grid;
  align-items: flex-start;
  align-content: flex-end;
  grid-template-rows: auto auto 1fr auto;
  overflow: auto;
  row-gap: var(--spacing-3);

  padding: var(--spacing-3);

  flex: 1;

  &-header {
    @include level;

    &-actions {
      @include control-group;
      column-gap: var(--spacing-2);
    }
  }

  &-sort {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    column-gap: var(--spacing-1);

    strong {
      font-weight: 500;
      color: var(--colour-neutral-light-slate-100);
    }
  }

  &-items {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
    max-height: 100%;
    min-height: 100%;
    overflow: auto;
    align-content: flex-start;
  }

  &-no-results {
    margin: var(--spacing-5);
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-2);

    justify-items: center;

    p {
      text-align: center;
    }

    .icon__search {
      height: 2.5rem;

      path {
        stroke: var(--colour-neutral-slate);
      }
    }
  }

  &-actions {
    display: grid;
    justify-content: end;
  }
}

.media-upload-widget {
  &__container {
    border-radius: var(--radius-large);
    background-color: var(--colour-primary-leaf-100);
    border: 1px solid var(--colour-primary-leaf-120);

    position: absolute;
    width: 22rem;
    bottom: var(--spacing-2);
    right: var(--spacing-2);

    padding: var(--spacing-2);
  }

  &__header {
    @include level;

    color: var(--colour-primary-leaf-25);

    &-actions {
      @include control-group;
      column-gap: var(--spacing-1);

      button {
        @include button-reset;

        svg {
          height: 20px;

          path {
            stroke: var(--colour-primary-leaf-25);
          }
        }
      }
    }
  }

  &__body {
    margin-top: var(--spacing-2);

    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
  }

  @import "./components/item";
}

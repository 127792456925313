&__editor {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;

  max-width: 1040px;

  & > .message {
    margin-bottom: var(--spacing-2);
    flex: 0;
  }

  &-header {
    @include level;
    margin-bottom: var(--spacing-2);
    flex: 0;

    h2 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 1.2;
    }

    &-actions {
      display: grid;
      grid-auto-flow: column;
      column-gap: var(--spacing-1);

      align-items: center;
    }

    &-publishing {
      display: grid;
      grid-auto-flow: column;
      column-gap: var(--spacing-1);

      align-items: center;

      span {
        font-weight: bold;
      }
    }
  }

  &-body {
    overflow: auto;
    flex: 1;
  }

  &-publishing {
    padding: var(--spacing-2);
    min-width: 15rem;

    &--targets {
      display: grid;

      li {
        margin-top: var(--spacing-2);
        padding-top: var(--spacing-2);
        border-top: 1px solid var(--colour-neutral-light-slate-25);

        display: grid;
        grid-template-columns: 1fr auto auto;
        column-gap: var(--spacing-1);
        align-items: center;

        span,
        small,
        button {
          white-space: nowrap;
        }

        span {
          margin-right: var(--spacing-2);
        }
      }
    }
  }

  &-publish {
    display: grid;
    row-gap: var(--spacing-1);

    &__actions {
      @include control-group;
    }
  }
}

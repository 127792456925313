.webhook-detail {
  &__container {
    display: grid;
    row-gap: var(--spacing-2);
    align-content: flex-start;
  }

  &__header {
    @include level;

    &-actions {
      @include control-group;
    }
  }

  &__log {
    @include box;
    @include gradient-background;

    display: grid;
    row-gap: var(--spacing-1);
    padding: var(--spacing-2);

    &-item {
      @include box(true);
      padding: var(--spacing-1);

      text-decoration: none;
      color: inherit;

      &:hover {
        color: inherit;
      }

      display: grid;
      grid-template-columns: 1fr auto;
      align-content: center;
    }
  }
}

@import "./markdown";
@import "./richtext";

.scalar-editor {
  &.--removable {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: var(--spacing-1);

    .button {
      svg {
        path {
          stroke: var(--colour-neutral-slate);
        }
      }
    }
  }
}

.entries-menu-item-editor {
  &__container {
    @include box;
    @include container-medium;

    display: grid;
    row-gap: var(--spacing-3);
    padding: var(--spacing-2);
    align-content: flex-start;
    margin: var(--spacing-2) auto;

    overflow: auto;
  }

  &__actions {
    @include control-group;
  }
}

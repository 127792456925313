:root {
  --input-colour: var(--colour-text);
  --input-colour-placeholder: var(--colour-neutral-light-slate-100);

  --input-background-colour: var(--colour-neutral-cloud);
  --input-background-colour-success: white;
  --input-background-colour-danger: white;
  --input-background-colour-focussed: white;
  --input-background-colour-disabled: var(--colour-neutral-cloud);

  --input-border-colour: var(--colour-neutral-light-slate-25);
  --input-border-colour-hover: var(--colour-neutral-light-slate-50);
  --input-border-colour-success: var(--colour-success);
  --input-border-colour-danger: var(--colour-danger);
  --input-border-colour-focussed: var(--colour-neutral-light-slate-100);
  --input-border-colour-disabled: var(--colour-neutral-dark-cloud);

  --input-radius: var(--radius);
}

.input,
.textarea {
  &::placeholder {
    color: var(--input-colour-placeholder);
  }

  border-radius: var(--input-radius);
  padding: 10px 14px;
  color: var(--input-colour);
  width: 100%;
  font-size: var(--font-size-base);
  line-height: 1.4;

  background-color: var(--input-background-colour);
  border: 1px solid var(--input-border-colour);

  &:hover {
    border-color: var(--input-border-colour-hover);
  }

  &.is-success {
    background-color: var(--input-background-colour-success);
    border-color: var(--input-border-colour-success);
    box-shadow: 0px 0px 0px 2px rgba(105, 221, 193, 0.2);
  }

  &.is-danger {
    background-color: var(--input-background-colour-danger);
    border-color: var(--input-border-colour-danger);
    box-shadow: 0px 0px 0px 2px rgba(241, 43, 44, 0.2);
  }

  &.is-focus,
  &:focus {
    outline: none;
    border-color: var(--input-border-colour-focussed);
    background-color: var(--input-background-colour-focussed);
    box-shadow: 0px 0px 0px 2px var(--colour-neutral-dark-cloud);
  }

  &[disabled] {
    background: var(--input-background-colour-disabled);
    box-shadow: none;
    border: 1px solid var(--input-border-colour-disabled);
  }

  & + .helper {
    display: block;
    margin-top: 0.5rem;
  }

  &__addon-container {
    background-color: var(--input-background-colour);
    border: 1px solid var(--input-border-colour);
    border-radius: var(--input-radius);
    padding: 10px 14px;
    display: flex;

    &.is-focus,
    &:focus-within {
      outline: none;
      border-color: var(--input-border-colour-focussed);
      background-color: var(--input-background-colour-focussed);
      box-shadow: 0px 0px 0px 2px var(--colour-neutral-dark-cloud);
    }

    & > .input,
    & > .textarea {
      flex: 1;

      padding: unset;
      background-color: unset;
      border: unset;
      border-radius: unset;

      &.is-focus,
      &:focus {
        outline: unset;
        border-color: unset;
        background-color: unset;
        box-shadow: unset;
      }
    }

    & button {
      @include button-reset;

      --icon-colour: var(--colour-neutral-light-slate-100);

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.textarea {
  resize: vertical;
  min-height: 10rem;
}

input::-webkit-input-placeholder {
  // https://stackoverflow.com/questions/9323596/placeholder-text-is-not-vertically-centered-in-safari
  line-height: normal !important;
}

input.input[type="search"] {
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

@import "./input-select";

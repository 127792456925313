&__filters {
  @include box;

  padding: var(--spacing-3);

  display: grid;
  overflow: hidden;
  align-self: start;
  width: 20rem;
  margin: var(--spacing-3);
  margin-left: 0;

  &-body {
    overflow: auto;
  }

  &-models {
    margin-top: var(--spacing-2);
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-2);
  }
}

.entries-settings {
  &__container {
    @include container-medium;

    margin: var(--spacing-2) auto;
  }

  &-item {
    &__body {
      &:not(:first-child) {
        margin-top: var(--spacing-1);
      }

      @include box(true);

      padding: 10px;

      display: grid;

      grid-template-columns: auto auto 1fr auto;
      column-gap: var(--spacing-1);
      align-items: center;

      &.--expandable {
        grid-template-columns: auto auto auto 1fr auto;
      }

      &.--over {
        border-color: var(--colour-primary-green-100);
      }

      &.active {
        border-color: var(--colour-primary-green-100);
      }

      button {
        @include button-reset;

        display: grid;
        align-items: center;
        justify-content: start;
        grid-auto-flow: column;
        column-gap: var(--spacing-1);

        svg {
          height: 20px;
          width: 20px;
        }
      }

      svg {
        height: 20px;
        width: 20px;

        stroke: var(--colour-neutral-light-slate-100);
      }

      &.--clone {
        margin-top: 1rem;
        margin-right: 1rem;
        display: inline-grid;
      }

      &.--ghost {
        background-color: var(--colour-neutral-light-slate-25);
        border-color: var(--colour-neutral-light-slate-25);

        & > * {
          visibility: hidden;
        }
      }
    }
  }
}

.breadcrumb {
  display: inline-grid;
  grid-auto-flow: column;
  row-gap: var(--spacing-2);
  justify-self: start;

  &.--small {
    font-size: var(--font-size-small);
  }

  li {
    a,
    span {
      color: inherit;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    &:not(:last-of-type):after {
      content: "/";
      margin: 0 var(--spacing-1);
      color: var(--colour-neutral-light-slate-100);
    }

    &:last-of-type {
      color: var(--colour-neutral-light-slate-100);
    }
  }
}

:root {
  --message-radius: var(--radius-small);

  --message-background-colour: var(--colour-neutral-light-slate-25);
  --message-background-colour-success: var(--colour-success-25);
  --message-background-colour-danger: var(--colour-danger-25);

  --message-border-colour: var(--colour-neutral-light-slate-75);
  --message-border-colour-success: var(--colour-success);
  --message-border-colour-danger: var(--colour-danger);

  --message-colour: var(--colour-neutral-onyx);
  --message-colour-success: var(--colour-primary-leaf-120);
  --message-colour-danger: var(--colour-danger);
}

.message {
  padding: var(--spacing-1);

  background-color: var(--message-background-colour);
  color: var(--message-colour);
  border: 1px solid var(--message-border-colour);
  border-left-width: 3px;

  border-radius: var(--message-radius);
  line-height: 1.2;

  &.is-small {
    font-size: var(--font-size-small);
  }

  &.is-success {
    background-color: var(--message-background-colour-success);
    color: var(--message-colour-success);
    border-color: var(--message-border-colour-success);
  }

  &.is-danger {
    background-color: var(--message-background-colour-danger);
    color: var(--message-colour-danger);
    border-color: var(--message-border-colour-danger);
  }
}

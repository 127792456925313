.icon-button {
  @include button-reset;
  text-decoration: none;
  display: inline-grid;
  border-radius: 8px;
  outline: none;
  padding: 10px;
  border-width: 2px;
  border-style: solid;

  &.--primary {
    background-color: var(--colour-primary-green-100);
    border-color: var(--colour-primary-green-100);
    color: white;

    &:hover,
    &.--hover {
      background-color: var(--colour-primary-green-120);
      border-color: var(--colour-primary-green-120);
    }

    &:focus,
    &.--focus {
      border-color: var(--colour-primary-green-140);
    }
  }

  &.--secondary {
    background-color: var(--colour-primary-green-25);
    border-color: var(--colour-primary-green-25);
    color: white;

    &:hover,
    &.--hover {
      background-color: var(--colour-primary-green-50);
      border-color: var(--colour-primary-green-50);
    }

    &:focus,
    &.--focus {
      border-color: var(--colour-primary-green-140);
    }
  }

  &.--tertiary {
    background-color: white;
    border-color: var(--colour-primary-green-100);
    color: var(--colour-neutral-slate);

    padding: 11px;
    border-width: 1px;

    &:hover,
    &.--hover {
      background-color: var(--colour-primary-green-25);
      border-color: var(--colour-primary-green-100);
    }

    &:focus,
    &.--focus {
      padding: 10px;
      border-width: 2px;

      border-color: var(--colour-primary-green-140);
    }
  }

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }

  &.--loading {
    pointer-events: none;
  }

  svg {
    height: 20px;
    width: 20px;
  }
}

.field {
  .control + .message {
    margin-top: 0.5rem;
  }
}

.help {
  display: block;
  line-height: 1.4;
  font-size: var(--font-size-small);
  margin-top: 0.5rem;
}

.control {
  position: relative;
  box-sizing: border-box;

  &.has-icon-right {
    .input {
      padding-right: 2.75rem;
    }

    .icon {
      top: 0;
      height: 2.75rem;
      width: 2.75rem;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;

      &.is-right {
        right: 0;
      }

      svg {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }

  &.is-inline {
    @include level;
  }

  &.has-icon-left {
    .input {
      padding-left: 2.5rem;
    }

    .icon {
      top: 0;
      height: 2.75rem;
      width: 2.75rem;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      &.is-left {
        left: 0;
      }

      svg {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }
}

body.--fix-fullheight {
  // TODO: addresses grid overflow bug in Chrome
  height: 100vh;
  overflow: hidden;
}

.project-layout {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;

  main {
    background-color: var(--colour-neutral-cloud);

    padding: var(--spacing-4);

    display: grid;
    min-height: 0;
  }

  &.--gapless {
    main {
      padding: 0;
    }
  }
}

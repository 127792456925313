.button-radio {
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.15rem;
  justify-self: flex-start;

  border-radius: var(--radius);
  border: 1px solid var(--colour-neutral-light-slate-25);
  background-color: var(--colour-secondary--sky-blue-25);

  li {
    button {
      border-radius: var(--radius);
      background-color: var(--colour-secondary--sky-blue-25);
      border: none;
      font-size: var(--font-size-small);
      padding: 0.5rem 0.75rem;
      cursor: pointer;

      will-change: background-color;
      transition: all ease-in 100ms;
    }

    &.is-active {
      button {
        background-color: var(--colour-secondary--sky-blue-100);
      }
    }
  }
}

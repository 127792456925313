.entries {
  &__container {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
  }

  &-collapse {
    @include button-reset;
    width: 1rem;
    background-color: var(--colour-neutral-light-slate-120);

    &.--collapsed {
      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      path {
        stroke: var(--colour-neutral-light-slate-75);
      }

      &:nth-child(1) {
        display: inline-block;
      }
      &:nth-child(2) {
        display: none;
      }
    }

    &:hover {
      svg {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          display: inline-block;
        }
      }
    }
  }

  &-tree {
    &__container {
      background-color: var(--colour-neutral-slate);
      color: white;
      display: flex;
      flex-direction: column;
      position: relative;
      height: calc(100vh - 66px); // TODO: variablise the 66px
      width: 15rem;

      &.--collapsed {
        width: 0rem;

        & > * {
          display: none;
        }
      }
    }

    &__body {
      flex: 1;
      padding: var(--spacing-2);
      overflow-x: auto;
    }

    &__footer {
      flex: 0;
      padding: var(--spacing-2);
      display: flex;
      justify-content: center;
      flex-direction: column;
      .field + .button {
        margin-top: var(--spacing-1);
      }
    }
  }
}

.taxonomy-tree {
  &__container {
    @include box;
    @include box-medium;
    @include gradient-background;

    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
    align-content: start;
    overflow: hidden;
  }

  &__targets {
    display: inline-grid;
    grid-auto-flow: column;
    column-gap: var(--spacing-1);
    align-items: center;
    justify-self: start;
  }

  &__nodes {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
    align-content: start;
    overflow: auto;
  }

  &__more {
    @include button-reset;
    justify-self: start;
    display: grid;

    grid-auto-flow: column;
    align-items: center;
    column-gap: var(--spacing-2);
    margin: var(--spacing-1) 0;

    span {
      color: var(--colour-primary-green-100);
      text-decoration: underline;
    }

    svg {
      height: 20px;
      width: 20px;

      path {
        stroke: var(--colour-primary-green-100);
      }
    }

    &:hover {
      span {
        color: var(--colour-primary-green-120);
      }
    }
  }

  &-item {
    &__container {
      @include box(true);

      padding: 10px;

      display: grid;

      grid-template-columns: auto 1fr 60vw;
      column-gap: var(--spacing-1);
      align-items: center;

      &.--expandable {
        grid-template-columns: auto auto 1fr 60vw;
      }

      button {
        @include button-reset;

        svg {
          height: 20px;
          width: 20px;

          path {
            stroke: var(--colour-neutral-light-slate-100);
          }
        }
      }

      span,
      input {
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.2;
      }

      input {
        padding: 0;
        border: none;
        font-size: inherit;
      }

      svg {
        height: 20px;
        width: 20px;

        stroke: var(--colour-neutral-light-slate-100);
      }
    }

    &__detail {
      display: grid;
      grid-template-columns: 1fr auto;
      column-gap: var(--spacing-2);
      align-items: center;

      a {
        line-height: 1.2;
      }
    }

    &__actions {
      display: grid;
      grid-auto-flow: column;
      column-gap: var(--spacing-1);
      align-items: center;

      a {
        svg {
          height: 1rem;
        }
      }
    }
  }
}

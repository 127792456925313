.taxonomy-editor {
  &__container {
    @include container-medium;
    justify-self: center;

    display: grid;
    row-gap: var(--spacing-2);
    align-content: flex-start;
    overflow: auto;
  }

  &__details {
    @include box;
    padding: var(--spacing-2);
    display: grid;
    row-gap: var(--spacing-2);
    align-content: flex-start;
  }

  &__detail {
    @include box;
    padding: var(--spacing-2);

    display: grid;
    row-gap: var(--spacing-2);
    align-content: flex-start;
  }

  &__actions {
    @include control-group;
  }
}

.taxonomy-levels {
  &__container {
    @include box;
    @include gradient-background;
    padding: var(--spacing-2);
    display: grid;
    row-gap: var(--spacing-2);
    align-content: flex-start;
  }
}

.taxonomy-level {
  &__container {
    @include box;
    padding: var(--spacing-2);
    display: grid;
    row-gap: var(--spacing-2);
    align-content: flex-start;
  }

  &__actions {
    @include control-group;
  }
}

.taxonomy-websites {
  &__container {
    @include box;
    padding: var(--spacing-2);
    display: grid;
    row-gap: var(--spacing-2);
    align-content: flex-start;
  }

  &__list {
    display: grid;
    row-gap: var(--spacing-2);

    li {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
    }
  }

  &__actions {
    @include control-group;
  }
}

&__watchers {
  @include box;
  padding: var(--spacing-3);

  margin-top: var(--spacing-4);
  display: grid;
  row-gap: var(--spacing-2);

  &-header {
    @include button-reset;
    @include level;

    svg {
      height: 20px;
    }
  }

  &-list {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
  }
}

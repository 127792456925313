.model-field-editor {
  &__container {
    @include box;

    padding: var(--spacing-2);
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-2);
    align-content: flex-start;
  }

  &__detail {
    display: grid;
    grid-template-columns: 60% 1fr;

    column-gap: var(--spacing-2);
  }

  &__actions {
    @include control-group;

    svg {
      path {
        stroke: var(--colour-neutral-slate);
      }
    }
  }

  &__configuration {
    @include box;
    @include gradient-background;

    padding: var(--spacing-2);
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-2);

    &-editor {
      display: grid;
      grid-auto-flow: row;
      row-gap: var(--spacing-2);
    }
  }

  &__value-count {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-2);
    padding-top: var(--spacing-2);
    border-top: 1px solid var(--colour-neutral-light-slate-25);

    &-range {
      display: grid;
      grid-auto-flow: column;
      column-gap: var(--spacing-2);
    }
  }
}

.media-item-list {
  &__container {
    @include container-medium;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .message {
      margin-top: var(--spacing-4);
    }

    .pagination__container {
      margin-top: var(--spacing-2);
    }
  }

  &__nav {
    @include level;

    margin-top: var(--spacing-4);

    h3 {
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.2;
    }

    input[type="file"] {
      @include hidden;
    }
  }

  &__items {
    min-height: 0;
    overflow: auto;
    flex: 1;

    &:not(.--empty) {
      @include box;
    }

    &.--empty {
      border: 1px solid transparent;
    }

    margin-top: var(--spacing-2);
    padding: var(--spacing-2);
    min-height: 50vh;

    display: flex;
    flex-direction: column;

    & > .media-card__item {
      &:not(:last-child) {
        margin-bottom: var(--spacing-1);
      }
    }

    &.--drop-active {
      background-color: var(--colour-secondary--sky-blue-25);
      border: 1px dashed var(--colour-primary-green-100);
      border-radius: var(--radius-large);
    }
  }

  &__empty {
    width: 20rem;
    justify-self: center;
    text-align: center;
    margin: 7rem;

    svg {
      height: 10rem;
    }
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    column-gap: var(--spacing-1);
  }
}

.media-item-detail {
  &__container {
    padding: 10px;

    display: grid;
    column-gap: var(--spacing-3);
    grid-template-columns: 1fr;

    &.--thumbnail {
      grid-template-columns: auto 1fr;
    }
  }

  &__thumbnail {
    img {
      height: 180px;
      width: 180px;
    }
  }

  &__editor {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-3);

    &-actions {
      padding-top: var(--spacing-2);
      border-top: 1px solid var(--colour-neutral-light-slate-25);

      @include control-group;

      svg {
        path {
          stroke: var(--colour-neutral-slate);
        }
      }
    }
  }
}

#media_drag_ghost {
  display: inline-block;
  background-color: var(--colour-neutral-onyx);
  color: #fff;
  font-size: var(--font-size-small);
  padding: 10px;
  border-radius: var(--radius);

  @include alert-shadow;
}

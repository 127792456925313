.two-column {
  &__container {
    @include container-large;

    display: grid;
    grid-template-columns: 28rem 1fr;
    grid-template-rows: auto 1fr;
    min-height: 0;
    column-gap: var(--spacing-3);
    row-gap: var(--spacing-2);
  }

  &__left,
  &__right {
    display: grid;
    min-height: 0;
    overflow: auto;
    grid-row: 2;
  }

  &__right {
    justify-items: center;

    & > * {
      width: 100%;
      max-width: 52rem;
    }
  }
}

.entry-search {
  &__container {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-3);
  }

  &__results {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
  }

  &__item {
    @include button-reset;
    @include box(true);

    &.--selected {
      border-color: var(--colour-primary-green-100);
    }

    padding: var(--spacing-2);
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);

    &-name {
      @include level;
    }

    &-detail {
      font-size: var(--font-size-small);
      justify-self: flex-start;

      display: grid;
      grid-auto-flow: column;
      column-gap: var(--spacing-2);
    }

    &-modified {
      display: grid;
      grid-auto-flow: column;
      column-gap: var(--spacing-1);
    }
  }

  &__footer {
    @include control-group;
  }
}

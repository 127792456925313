.webhook-settings {
  &__container {
    display: grid;
    row-gap: var(--spacing-2);
    align-content: flex-start;
  }

  &__header {
    @include level;

    &-actions {
      @include control-group;
    }
  }

  &__form {
    display: grid;
    row-gap: var(--spacing-2);
  }

  &__headers {
    display: grid;
    row-gap: var(--spacing-2);

    &-list {
      display: grid;
      row-gap: var(--spacing-1);
    }

    &-item {
      display: grid;
      grid-template-columns: 1fr 2fr;
      column-gap: var(--spacing-1);
    }
  }
}

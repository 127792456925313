.folder-selector {
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 16rem;
    height: 100%;

    @include box;
    padding: var(--spacing-1);
    overflow: auto;
  }

  &__header {
    button {
      @include button-reset;
      margin-bottom: var(--spacing-1);

      svg {
        height: 20px;
      }
    }
  }

  &__items {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);
    overflow: hidden;
  }

  &-item {
    @include box;

    padding: 10px;

    display: grid;
    column-gap: var(--spacing-1);
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: start;

    &.--expandable {
      grid-template-columns: auto 1fr;
    }

    button {
      @include button-reset;
      line-height: 1;
    }

    svg {
      height: 20px;
      width: 20px;
    }

    &__action {
      display: grid;
      column-gap: var(--spacing-1);
      grid-auto-flow: column;
      align-items: center;

      span {
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.2;
      }
    }
  }
}

.checkbox {
  &:not(:checked),
  &:checked {
    position: absolute;
    left: -9999px;

    & + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: calc(var(--spacing-3) - 2px); // to account for the border
      height: calc(var(--spacing-3) - 2px);
      border: 1px solid var(--colour-neutral-light-slate-100);
      background: white;
      border-radius: 6px;
    }
  }

  & + label {
    position: relative;
    padding-left: calc(var(--spacing-3) + var(--spacing-1));
    cursor: pointer;
    height: var(--spacing-3);
    display: grid;
    align-items: center;
  }

  &:checked {
    & + label:before {
      border-color: var(--colour-primary-green-100);
      background-color: var(--colour-primary-green-75);
    }

    & + label:after {
      content: url('data:image/svg+xml;utf8,<svg height="12" width="16" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.799988 7.42608L3.86923 10.3619C4.58503 11.0466 5.71962 11.0239 6.40746 10.3111L15.2 1.2" stroke="white" stroke-width="1.25"/></svg>');
      position: absolute;
      top: 0;
      left: 0;
      height: var(--spacing-3);
      width: var(--spacing-3);
      display: grid;
      align-items: center;
      justify-items: center;
    }

    &:hover {
      & + label:before {
        border-color: var(--colour-primary-green-120);
        background-color: var(--colour-primary-green-100);
      }
    }
  }

  &:not(:checked) {
    &:hover {
      & + label:before {
        border-color: var(--colour-primary-green-100);
        background-color: var(--colour-primary-green-25);
      }
    }
  }
}

.model-settings {
  &__container {
    @include box;

    padding: var(--spacing-2);

    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-2);
    align-content: flex-start;
  }

  &__actions {
    @include control-group;

    .button {
      svg {
        path {
          stroke: var(--colour-neutral-slate);
        }
      }
    }

    small {
      line-height: 1.2;
    }
  }

  &__inheritance {
    @include box;
    @include gradient-background;

    padding: var(--spacing-2);

    .help {
      margin-top: 1rem;
    }

    &-header {
      @include level;
      margin-bottom: var(--spacing-2);

      button {
        font-weight: normal;
        font-size: 0.75rem;
      }
    }

    &-items {
      display: grid;
      grid-auto-flow: row;
      row-gap: var(--spacing-1);

      li {
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: var(--spacing-1);

        svg {
          path {
            stroke: var(--colour-neutral-slate);
          }
        }
      }
    }
  }

  &__field-order {
    @include box;
    @include gradient-background;
    padding: var(--spacing-2);

    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);

    &-container {
      @include box;

      padding: var(--spacing-1);
      display: grid;
      grid-auto-flow: column;
      column-gap: 1rem;
      justify-content: start;
    }

    &-grab-handle {
      cursor: ns-resize;

      svg {
        height: 1rem;
      }
    }
  }
}

@import "./editor";
@import "./settings";

.entries-tree {
  &-item {
    &__container {
      &:not(:first-child) {
        margin-top: var(--spacing-1);
      }
    }

    &__body {
      --icon-colour: var(--colour-neutral-light-slate-25);

      @include box(false);

      background-color: var(--colour-neutral-onyx);
      border-color: var(--colour-neutral-onyx);
      color: var(--colour-neutral-light-slate-25);

      padding: 10px;

      display: grid;

      grid-template-columns: auto 1fr auto;
      column-gap: var(--spacing-1);
      align-items: center;

      &.--expandable {
        grid-template-columns: auto auto 1fr auto;

        &.--folder {
          grid-template-columns: auto 1fr auto;
        }
      }

      &.--over {
        border-color: var(--colour-primary-green-100);
      }

      &.active {
        border-color: var(--colour-primary-green-100);
      }

      button {
        @include button-reset;

        display: grid;
        align-items: center;
        justify-content: start;
        grid-auto-flow: column;
        column-gap: var(--spacing-1);

        svg {
          height: 20px;
          width: 20px;
        }
      }

      a,
      button {
        color: var(--colour-neutral-light-slate-25);
        text-decoration: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.1;

        &.active,
        &:hover {
          color: white;
        }
      }

      span {
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.2;
      }

      svg {
        height: 20px;
        width: 20px;

        stroke: var(--colour-neutral-light-slate-100);
      }
    }

    &__detail {
      display: grid;
      grid-template-columns: 1fr auto;
      column-gap: var(--spacing-2);
      align-items: center;

      a {
        line-height: 1.2;
      }
    }

    &__actions {
      display: grid;
      grid-auto-flow: column;
      column-gap: var(--spacing-1);
      align-items: center;

      a {
        svg {
          height: 1rem;
        }
      }
    }
  }

  &__footer {
    display: grid;
    row-gap: var(--spacing-1);

    .button {
      background-color: var(--colour-neutral-light-slate-100);
      border-color: var(--colour-neutral-light-slate-100);
      flex: 1;

      --icon-colour: white;

      .button__content {
        color: white;
      }
    }
  }
}

&__versions {
  @include box;
  padding: var(--spacing-3);

  margin-top: var(--spacing-4);
  display: grid;
  row-gap: var(--spacing-2);

  &-header {
    @include button-reset;
    @include level;

    svg {
      height: 20px;
    }
  }

  &-list {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-1);

    a {
      @include box(true);

      display: grid;
      grid-template-columns: 1fr auto auto;
      align-items: center;
      column-gap: var(--spacing-1);

      @include breakpoint(large) {
        column-gap: var(--spacing-2);
      }

      padding: 10px; // TODO: review use of pixels
      text-decoration: none;
      color: inherit;
      line-height: 1;
      overflow: hidden;

      time {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        span {
          &:not(:first-child) {
            display: none;

            @include breakpoint(large) {
              display: initial;
            }
          }
        }
      }

      &:hover {
        border-color: var(--colour-secondary--sky-blue-120);
      }

      &.active {
        border-color: var(--colour-secondary--sky-blue-100);
        background-color: var(--colour-secondary--sky-blue-25);
      }

      .--status {
        font-size: var(--font-size-small);
        color: var(--colour-neutral-light-slate-100);
        position: relative;
        display: grid;
        grid-auto-flow: column;
        column-gap: var(--spacing-1);

        .--published {
          // TODO: review use of pixels
          margin-left: 12px;
          font-size: 0;

          @include breakpoint(large) {
            font-size: inherit;
          }

          &:before {
            position: absolute;

            height: 4px;
            left: -8px;
            width: 4px;
            border-radius: 4px;
            top: -6px;

            @include breakpoint(large) {
              top: unset;
            }

            margin-top: 4px;

            background-color: var(--colour-success-100);
            content: " ";
          }
        }
      }

      .--delete {
        @include button-reset;
        height: 14px;
        width: 14px;

        svg {
          path {
            fill: var(--colour-neutral-light-slate-100);
          }
        }
      }
    }
  }
}
